import React from 'react';
import Home from '../Home/Home'
import Plans from '../Plans/Plans'
import Features from '../Features/Features'
import Footer from '../../components/Footer/Footer'
import Services from '../Services/Services';
import FAQ from '../FAQ/FAQ';
import Reviews from '../Reviews/Reviews';

export default function Main() {
  return <div>
      <Home />  
      <Features />   
      <Plans />  
      <Services /> 
      <FAQ />
      <Reviews />
      <Footer />
  </div>;
}
