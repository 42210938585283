import React from 'react'
import "aos/dist/aos.css";
import './styles.scss'
import f1 from '../../images/f1.png'
import f2 from '../../images/f2.png'
import f3 from '../../images/f3.png'
import f4 from '../../images/f4.png'
import f5 from '../../images/f5.png'
import f6 from '../../images/f6.png'
import FeaturesCard from './FeaturesCard/FeaturesCard';

export default function Features() {

  return (
    <div className='features-wrapper' id='features' >
      <div className='features-container'>
        <h5>Our features</h5>
        <div>
          {[
            {
              icon: f1,
              title: "Top Quality Proxies ",
              para: "LYTE proxies are very fast and secure. We work with Supreme, Shopify, Footsites, SNKRS, Adidas, and much more!"
           
            },
            {
              icon: f2,
              title: "Easy Plans to Choose",
              para: "Currently LYTE offers Basic and Deluxe Plans for Residental Data. We also offer Daily ISPs for a very competitive price. Scroll down to checkout each plan's features. "
          
            },
            {
              icon: f3,
              title: "Discord Support",
              para: "we offer 24/7 support in our Discord server. Please feel free to reach out with any questions, and our responsible team will gladly assist. ",
              link: "https://discord.gg/U2fQ3AaDaa"
          
            },
            {
              icon: f4,
              title: "Competitive Plans",
              para: "Monthly and weekly plans with the most competitive pricing in the market. Please check our products below for more information on different plans. "
          
            },
            {
              icon: f5,
              title: "User Friendly Dashboards",
              para: "Our user-friendly dashboard allows our customers to make more insightful decisions. You can review your remaining  balance from the dashboard. "
          
            },
            // {
            //   icon: f6,
            //   title: "24 / 7 Support",
            //   para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.consectetur adipiscing elit. "
          
            // },
           
          ].map((item, i) => (
            <FeaturesCard key={i} data={item}/>
          ))}
        </div>
      </div>
    </div>
  )
}
