import React, {useEffect} from 'react';
import { HashLink } from 'react-router-hash-link';
import AOS from "aos";
import { Link } from 'react-router-dom';
import home from '../../images/home.svg'
import './styles.scss'

export default function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return  <div className='home-wrapper' id="home" data-aos="zoom-in">
      <div className='home-container'>
          <div className='right'>
            <h1>Simple | Fast | Safety is what makes Lyte<span>Proxies</span> the best.</h1>
            <div className='button-container'>
              <button className='button1'><Link to={ {pathname:"https://dashboard.lyteproxies.com/" }} target="_blank">Continue with Lyte<span>Proxies</span></Link></button>
              <button className='button2'> <HashLink smooth to="/#features">Learn more about Lyte<span>Proxies</span></HashLink></button>
            </div>
          
          </div>   
          <div className='left'>
            <img src={home} alt="" data-aos="fade-down" />
          </div>
            
      </div>
  </div>;
}
