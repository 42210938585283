import React, {useEffect, useState} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './styles.scss'

export default function PlanCard(props) {
    const {data} = props;
    const [selectValue, setSelectValue] = useState(data.p1)
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
 
  return (
    <div className='planCard-wrapper' data-aos="flip-down">
            <div className='title-container'><h3>{data.title}</h3><h1>${selectValue}</h1></div>
            <div className='features'>
              <ul>
                  <li>{data.l1}</li>
                  <li>{data.l2}</li>
                  {data.l3 && <li>{data.l3}</li>}
                  {data.l4 && <li>{data.l4}</li>}
                  {data.l5 && <li>{data.l5}</li>}
                  {data.l6 && <li>{data.l6}</li>}
                  {data.l7 && <li>{data.l7}</li>}
                  {data.l8 && <li>{data.l8}</li>}
                  {data.l9 && <li>{data.l9}</li>}
                  {data.l10 && <li>{data.l10}</li>}
                  {data.l11 && <li>{data.l11}</li>}
              </ul>
            </div>

            <div className='selector'>
            <select value={selectValue} onChange={(e)=>setSelectValue(e.target.value)}>
                <option value={data.p1}>{data.opt1}</option>
                {data.opt2 && <option value={data.p2}>{data.opt2}</option>}
                {data.opt3 && <option value={data.p3}>{data.opt3}</option>}
                {data.opt4 && <option value={data.p4}>{data.opt4}</option>}
                {data.opt5 && <option value={data.p5}>{data.opt5}</option>}
                {data.opt6 && <option value={data.p6}>{data.opt6}</option>}
                {data.opt7 && <option value={data.p7}>{data.opt7}</option>}
                
            </select>
            </div>
           
           
           
            <a href="https://dashboard.lyteproxies.com"><button>Buy Now</button></a>
    </div>
  )
}
