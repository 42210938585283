import React, {useEffect} from 'react';
import AOS from "aos";
import services from '../../images/services.png'
import s1 from '../../images/s1.png'
import s2 from '../../images/s2.png'
import s3 from '../../images/s3.png'
import './styles.scss'

export default function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
  return (
    <div className='services-wrapper'>
        <div className='services-container'>
            <div className='content'>
                <div className='left'>
                    <h5>Benefits of joining <br />the LYTE Community</h5>
                    <p>Lyte proxies support sneaker communities by offering  the best proxies at affordable prices. Our Discord is a home of inspiration to many Sneakerheads where we offer the best tactics and practices. Join us today and become a member of our community!</p>
                </div>
                <div className='right'>
                    <div className='box1'>
                        <div>
                            <h3>Fast Proxies</h3>
                            <p>Ours residential speeds can vary anywhere from 200ms to 800ms 🚀</p>
                        </div>
                        <img src={s1} alt="" />
                    </div>
                    <div className='box2'>
                        <div>
                            <h3>Safest Proxies</h3>
                            <p>Our Residental Plans never expire!</p>
                        </div>
                        <img src={s2} alt="" />
                    </div>
                    <div className='box3'>
                        <div>
                            <h3>Easy to Handle with Sophisticated Dashboards</h3>
                            <p>LYTE Dashboard reflects a seamless interface. Checkout  <a href="https://dashboard.lyteproxies.com" >https://dashboard.lyteproxies.com</a> to make a purchase </p>
                        </div>
                        <img src={s3} alt="" />
                    </div>

                </div>
            </div>
            <img src={services} alt=""  data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500"/>
        </div>
    </div>
  )
}
