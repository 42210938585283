import React from 'react'
import './styles.scss'

export default function TOS() {
  return (
    <div className='tos-wrapper'>
      <div className='tos-container '>
    
        <h4>LYTE Proxies Terms of Services </h4>
        <p>Terms & ConditionsWelcome to Lyte Proxies. If you continue to use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Lyte Proxies relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website</p>
        <h3>1. Using Lyte Proxies </h3>
        <p>We are not responsible for any financial losses or any other damages caused whatsoever. When using Lyte Proxies, you agree to and understand that you will not:</p>
        <ol type = 'i'>
          <li> Use our proxies on any illegal streaming websites or platforms </li>
          <li> Infringe any third party rights</li>
        </ol>
        <p>Lyte Proxies grant you limited access and use to the website. Users must not download, modify or copy any part of our website including information and content. The user agrees not to collect or store any product information.</p>
        <h3>2. Content </h3>
        <p>You are completely responsible for the material and content you submit to our website. We are not to be held liable for any content posted nor are we to be held responsible.</p>
        <p>We may monitor and record traffic to our site and collect the following information including but not limited to the IP address of your computer and the website which referred you to our website. We do this, so we can make ongoing improvements on our site based on what our customer’s actions are.</p>
        <p>All content including but not limited to pictures, videos and text are copyrighted by Lyte Proxies unless stated. The pictures on our website for visual representation only.</p>
        <p>You may not copy, reproduce, distribute or store any material on our website.</p>
        <h3>3. Our Cancellation and Refund Policy</h3>
        <p>We do not offer any refunds on any orders. Our proxies are all tested before we deliver them. We are not responsible for any proxy bans due to incorrect setups or running more than 1:1 task/proxy ratio.</p>
        <h3>4. Personal Information</h3>
        <p>We may collect and store your personal information for reasons such as improving our site and making the user experience better. We may use them for advertisement purposes. We will never sell, rent or give your personal data to any third party company without your consent.</p>
        <p>If we do, we will only collect personal identification information from users only if they themselves submit such information to us. Users can refuse to supply such information but it may stop them from using certain sites or accessing specific parts of our website.</p>
        
        <p>We are not to be held responsible for any breaches to our security caused by third party. Keeping your personal information is of the utmost importance to us however we are not to be held liable for any data breaches.</p>
        
        <p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
        
        <p>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</p>
        
        <p>If you opt to sign up to our newsletter we may use your email address to send you information about our products or services. You do have the option to opt out of our mailing list and you can ask for personal data to stop being recorded at any time.</p>
        
        <p>We reserve the right to disclose user information and personal information to law enforcement or authorities should we feel the need to.</p>

        <p>The user agrees to be bound by the Lyte Proxies privacy policy which are available here.</p>
        
        <p>Lyte Proxies are not liable for any failure or delay in performance or its obligations which is due to the result of any act, event, terrorism act, acts of God, governmental regulations, war, riots, fire, flood or anything else which is beyond our control.</p>
        
        <h3>5. Commitment </h3>
        <ol type = 'i'>
          <li>You must be at least 13 years old to participate </li>
          <li>You must not be prohibited from receiving or participating in any aspects of our Service </li>
        </ol>
        <h3>6. General</h3>
        <p>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</p>
        <p>This website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</p>
        <p>When using our linguistic services, you are responsible for all damages and losses and Lyte Proxies are not to be held liable for any damages or losses whatsoever.</p>
        <p>We do not accept any liability or responsibility for any delays caused by third parties. Your use of this website and any dispute arising out of such use of the website is subject to the laws of your specific country.</p>
        <p>This website is owned and operated by Lyte Proxies.</p>
  
      </div>
    </div>
  )
}
