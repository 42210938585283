import React, {useState, useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import arrow from '../../images/arrow.png'
import './FAQ.scss'

export default function FAQ() {
    useEffect(() => {
        AOS.init();
      }, []);

    const [faq1Clicked, setFaq1Clicked] = useState(false);
    const [faq2Clicked, setFaq2Clicked] = useState(false);
    const [faq3Clicked, setFaq3Clicked] = useState(false);
    const [faq4Clicked, setFaq4Clicked] = useState(false);

    useEffect(() => {
        if(faq1Clicked === true){
            setFaq2Clicked(false)
            setFaq3Clicked(false)
            setFaq4Clicked(false)
        }
      }, [faq1Clicked]);

      useEffect(() => {
        if(faq2Clicked === true){
            setFaq1Clicked(false)
            setFaq3Clicked(false)
            setFaq4Clicked(false)
        }
      }, [faq2Clicked]);

      useEffect(() => {
        if(faq3Clicked === true){
            setFaq1Clicked(false)
            setFaq2Clicked(false)
            setFaq4Clicked(false)
        }
      }, [faq3Clicked]);

      useEffect(() => {
        if(faq4Clicked === true){
            setFaq1Clicked(false)
            setFaq2Clicked(false)
            setFaq3Clicked(false)
        }
      }, [faq4Clicked]);

  return <div className='faq-wrapper' id="faq">
      <div className='faq-container'>
            <h5>See what people ask frequently</h5>
            <p>If you have other questions please submit a ticket via our Discord Server and we'll be on it! </p>
            <div onClick={() => setFaq1Clicked(!faq1Clicked)} data-aos="fade-up" className='faqcard-wrapper'>
                <div className='faqs'>
                    <h6>Why choose LYTE Proxies?</h6>
                    {faq1Clicked === true ? <p>You want results. We hear you. Our team is comprised of experts, who have over 10 years of combined experience in the sneaker industry. Choose LYTE Proxies and you’ll work with seasoned professionals – vigilant of deadlines, and committed to exceeding user expectations.</p> : ""}
                </div>
                <div className='selector-container'><img src={arrow} alt='' className={faq1Clicked === false ? "" : "clicked"}/></div>
            </div>
            <div onClick={() => setFaq2Clicked(!faq2Clicked)} data-aos="fade-up" data-aos-anchor-placement="top-bottom" className='faqcard-wrapper'>
                <div className='faqs'>
                    <h6>When My Proxies Will be Dilivered ?</h6>
                    {faq2Clicked === true ?<p>Residential Plans are instant! ISP orders are generated 24 hours after the purchase is completed. </p> :""}
                </div>
                <div className='selector-container'><img src={arrow} alt='' className={faq2Clicked === false ? "" : "clicked"}/></div>
            </div>
            <div onClick={() => setFaq3Clicked(!faq3Clicked)}  data-aos="fade-up" data-aos-anchor-placement="top-bottom" className='faqcard-wrapper'>
                <div className='faqs'>
                    <h6>What Sites Do We Support ?</h6>
                    {faq3Clicked === true ?<p>Premium plan supports: Raffle sites, Footsites, Nike, Supreme, Yeezy, Deluxe plan supports: Footsites (US&EU), Nike, Supreme, Shopify, Walmart, Target, Yeezy Supply, Adidas (For detailed information checkout our Discord #products).</p> : ""}
                </div>
                <div className='selector-container'><img src={arrow} alt='' className={faq3Clicked === false ? "" : "clicked"}/></div>
            </div>
            <div onClick={() => setFaq4Clicked(!faq4Clicked)} data-aos="fade-up" data-aos-anchor-placement="top-bottom" className='faqcard-wrapper'>
                <div className='faqs'>
                    <h6>What kind of Authentication Will My Proxies Be ?</h6>
                    {faq4Clicked === true ?<p>We will generate you a username and password.</p> : ""}
                </div>
                <div className='selector-container'><img src={arrow} alt='' className={faq4Clicked === false ? "" : "clicked"}/></div>
            </div>
      </div>
  </div>;
}
