import React, {useState} from 'react'
import PlanCard from './PlanCard/PlanCard'
import './styles.scss'

export default function Plans() {
  const [clicked, setClicked] = useState('isp')
  return (
    <div className='plans-wrapper' id="services">
      <div className='plans-container'>
        <h5>Lyte Products</h5>
        <div className='tabs-container'>
          <button className={clicked === 'isp' ? 'selected' : ""} onClick={()=>setClicked('isp')}>ISPs</button>
          <button onClick={()=>setClicked('residencial')} className={clicked === 'residencial' ? 'selected' : ""}>Residentials</button>
        </div>
        {clicked === 'isp'  && <div className='content'>
          {[

              {
                title: "Daily ISPs",

                l1: "Unbanned on all Sneaker websites (Not guaranteed on Footsites)",               
                l2: "10GPs Uplink",
                l3: "As low as 10ms on servers!",
                l4: "Amazing on Retail sites!",
                l5: "Hosted in Ashburn, VA",
                l6: 'All Virgin IPs',
                l7: "Supports all retail sites and MORE",

                opt1: "25 ISP Proxies",
                opt2: "50 ISP Proxies",
                opt3: "75 ISP Proxies",
                opt4: "100 ISP Proxies",
                opt5: "200 ISP Proxies",

                p1: "16.25",
                p2: "32.50",
                p3: "48.75",
                p4: "65.00",
                p5: "130.00",
              },

              {
                title: "Monthly ISPs",

                l1: "Dedicated Sprint/Cox/Windstream/ ATT ISPs",
                l2: "Located in Ashburn, Virginia",
                l3: "Unlocked 24/7",
                l4: "Private 10 GB/s Network",
                l5: "Unlimited Bandwidth",
                l6: "User pass authentication ",
                l7: "30 Day Duration",
                l8: "24 Hour Delivery",
                l9: "Non-Renewable",
                l10: "Cannot guarantee on footsites or PP drops",

                opt1: "25 ISP Proxies",
                opt2: "50 ISP Proxies",
                opt3: "75 ISP Proxies",
                opt4: "100 ISP Proxies",
              
                p1: "75",
                p2: "150",
                p3: "225",
                p4: "300",
            
                
              },
              {
                title: "ISP Subnets",          
   
                l1: "Subnets come with 254 ISPs.",
                l2: "Fully dedicated and private to the user",
                l3: "Have full control of their own subnet",
                l4: "Fully unlocked",
                l5: "Supports Yeezy Supply and Retail sites",
                l6: "Cannot be guaranteed for Proxy Protection drops",
                
                opt1: "254 ISP Subnets",
              
                p1: "400",            
                
              },
             

            
       
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
        </div>}

        {clicked === 'residencial'  && <div className='content'>
          {[
            {
              title: "Deluxe Residental Plan ",

              l1: "IP pools with 100 Million residential IPs",
              l2: "Rotating and Sticky options supporting All regions/countries",
              l3: "Unbanned on all sites and extremely fast on Footsites with millions of IPs in our private pool!",
              l4: "Private network and never throttled EVER",
              l5: "All plans never expire!  ",
              l6: "Instant delivery",
             
              opt1: "1GB",
              opt2: "2GB",
              opt3: "4GB",
              opt4: "8GB",
              opt5: "12GB",
              opt6: "15GB",
            
              p1: "22",
              p2: "44",
              p3: "80",
              p4: "160",
              p5: "240",
              p6: "300"
              
            },
            {
              title: "Premium Residental Plan",

              l1: "IP pools with 7 Million residential IPs",
              l2: "Unbanned on all sites and extremely fast on Footsites with millions of IPs in our private pool!",
              l3: "Rotating and Sticky options supporting All regions/countries",
              l4: "Private network and never throttled EVER",
              l5: "All plans never expire!",
              l6: "Instant delivery",
              
              opt1: "1GB",
              opt2: "2GB",
              opt3: "4GB",
              opt4: "8GB",
              opt5: "12GB",
              opt6: "15GB",
            
              p1: "12",
              p2: "22.80",
              p3: "43.55",
              p4: "92.60",
              p5: "138",
              p6: "168.25"
              
            },
          
        
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
        </div>}
      
      </div>
    </div>
  )
}
