import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MobileNavbar from './components/MobileNavbar/MobileNavbar';
import Navbar from './components/Navbar/Navbar'
import Main from './pages/Main/Main';
import TOS from './pages/TOS/TOS';

function App() {
  return (
    <div className="app">
        <Router>
          <Navbar />
          <MobileNavbar />
          <Switch>
          <Route exact path="/termsOfServices" component={TOS}/>
            <Route path="/" component={Main} />
          </Switch>
        </Router>
    </div>
  );
}

export default App;
