import React, {useEffect} from 'react';
import AOS from "aos";
import copyright from '../../images/copyright.svg'
import logo from '../../images/logo.png'
import mail from '../../images/mail.png'
import discord from '../../images/discord.png'
import twitter from '../../images/twitter.png'
import './styles.scss'


export default function Footer() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='footer-wrapper' id="social">
      <div className='footer-content' >
        <div className='top'>
          <div className='box1'>
            <img src={logo} alt="" />
            <p>Lyte Proxies offers the highest quality proxies. Make sure to follow our socials to be the first one to know about discounts, new products, and exclusive prizes! </p>
            <div>
            <img src={mail} alt="" />
              <p>We would love to hear from you! Please email <a href="mailto:help@lyteproxies.com">help@lyteproxies.com</a> &nbsp;and our team will get back to you shortly.</p>
            </div> 
          </div>
          <div className='box2'>
            <h6>Quick Links</h6>
            <p>Privacy Policy</p>
            <a href="/termsOfServices">Terms of Service</a>
          </div>
          <div className='box3'>
            <div>
              <p>Stay Connected With Social Platforms</p>
            </div>
          
          <div>
             <a href="https://discord.gg/U2fQ3AaDaa"><img src={discord} alt="" /></a>
             <p>Join our Discord and become a part of our community!</p>
           </div>
           <div>
           <a href="https://twitter.com/LyteProxies"><img src={twitter} alt="" /></a>
             <p>Follow us on Twitter to be the first to win the prizes and more!</p>
           </div>
          </div>
        </div>     
       
       
      </div>
      <div className='bottom'>
           <img src={copyright} alt="" />
           <p>copyright <span>Lyte</span>Proxies 2022</p>
         </div>
    </div>
  )
}
