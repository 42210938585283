import React from 'react'
import Carousel, { CarouselItem } from "../../components/Carousel/Carousel";
import './styles.scss'

export default function Reviews() {
  return (
    <div className='reviews-wrapper'>
        <div className='reviews-container'>
        <Carousel>
          <CarouselItem> 
            <div className='reviewCard-wrapper'>
                <div className='reviewCard-container'>
                    <div className='left'>
                        <h1>5.0<span>/5.0</span></h1>
                    </div>
                    <div className='right'>
                        <p>‘’ Lyte proxies is amazing. I personally have been using Lyte proxies for all my drops and it is working better than I could imagine. The owner and supports is always active and willing to help those that needed help with their orders. “</p>
                        <p className='name'>Chef Benry, Ceo of Coral Utility</p>
                    </div>
                </div>
            </div>
          </CarouselItem>
          <CarouselItem> 
          <div className='reviewCard-wrapper'>
              <div className='reviewCard-container'>
                  <div className='left'>
                      <h1>5.0<span>/5.0</span></h1>
                  </div>
                  <div className='right'>
                      <p>‘’Lyte’s intimate user base is perfect for all your needs, be it retail, sneakers, clothing or collectibles. I’ve found a one-stop shop to handle all my needs at a great value and feel like family, not just a number. Would highly recommend for all your proxy needs.“</p>
                      <p className='name'>uscs04#4410</p>
                  </div>
              </div>
          </div>
          </CarouselItem>
          <CarouselItem> 
          <div className='reviewCard-wrapper'>
              <div className='reviewCard-container'>
                  <div className='left'>
                      <h1>5.0<span>/5.0</span></h1>
                  </div>
                  <div className='right'>
                      <p>‘’I first came across Lyte during a GB with my CG and I’m glad I joined. Consistent, clean pools with proven success from the start. First drop running Lyte was a cookout. No issues whatsoever that you see with other providers. Staff is attentive and interactive, always on top of things. If your looking for clean proxies, competent and attentive staff and a great deal, look no further.“</p>
                      <p className='name'>Wang, Administrator</p>
                  </div>
              </div>
          </div>
          </CarouselItem>
          <CarouselItem> 
          <div className='reviewCard-wrapper'>
              <div className='reviewCard-container'>
                  <div className='left'>
                      <h1>5.0<span>/5.0</span></h1>
                  </div>
                  <div className='right'>
                        <p>‘’Lyte Proxies is a great proxy company which will provide you with the tools you need to bot. Their proxies work great and the community there is kind and helping. Sometimes, the generous owners will throw in something extra when you purchase, or give you a discount so you can have the most for the amount you’re paying. Lyte Proxies is really great, I highly recommend.</p>
                        <p className='name'>Thisisgerald#6142</p>                     
                  </div>
              </div>
          </div>
          </CarouselItem>
        </Carousel>
           
        </div>
    </div>
  )
}
