import React, {useEffect, useState} from 'react';
import './Navbar.scss';
import AOS from "aos";
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'

function Navbar() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
    const [nav, setNav] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 100) {
          setNav(true);
        } else {
          setNav(false);
        }
      };

      useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => {
          window.removeEventListener('scroll', changeBackground);
        };
      }, []);

    return (
        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom"className={nav ? 'navbar-wrapper active' : 'navbar-wrapper'}>
            <div className='navbar-container'>
                <img src={logo} alt="" />
                <div data-aos="zoom-in">
                    <HashLink smooth to="/#home">Home</HashLink>
                    <HashLink smooth to="/#features">Features</HashLink>
                    <HashLink smooth to="/#services">Plans</HashLink>
                    <HashLink smooth to="/#faq">FAQ</HashLink>
                    <HashLink smooth to="/#social">Social</HashLink>
                </div>
                <div>
                  <button><Link to={ {pathname:"https://dashboard.lyteproxies.com/" }} target="_blank">Dashboard</Link></button>
                </div>                  
            </div>
        </div>
    )
}

export default Navbar
